<template>
  <div class="codeContainer">
    <p style="font-size: 24px">Step 2 : 输入您获得的验证码</p>
    <div class="codeForm">
      <el-form @submit.native.prevent>
        <el-form-item
          :rules="[
      { required: true, message: '请输入验证码', trigger: 'blur' }
    ]"
        >
          <el-input prefix-icon="el-icon-paperclip" v-model="CODE" style="min-width: 200px" v-on:change="passCode"></el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RegisterStepTwo',
  data: function () {
    return {
      CODE: '',
      rules: {}
    }
  },
  methods: {
    passCode () {
      this.$emit('func', this.CODE)
    }
  }
}
</script>

<style scoped>
.codeContainer{
  padding: 20px 30px;
  box-sizing: border-box;
}
.codeForm{
  margin: 65px 30px;
}
</style>
